import React from "react"

export default function About() {
    return (
        <div className="About--container">
            <span className="About--about">
                <h3 className="About--title">About</h3>
                <p className="About--description">Lorem Ipsum</p>
            </span>
            <span className="About--interests">
                <h3 className="About--title">Interests</h3>
                <p className="About--description">Lorem Ipsum</p>
            </span>
        </div>
    )
}