import React from "react"

export default function Info() {
    return (
        <div className="Info--Container">
            <img className="Info--profile_picture" src={process.env.PUBLIC_URL + '/images/1370.jpg'} alt="ZarqulTech Founder" />
            <div className="Info--details_container">
                <h1 className="Info--name">Jørgen Johansen</h1>
                <h2 className="Info--position">Web developer</h2>
                <h3 className="Info--website">zarqul.it</h3>
                <div className="Info--buttons">
                    <button className="Info--email"><i class="bi bi-envelope-fill"></i>Email</button>
                    <button className="Info--linkedin"><i class="bi bi-linkedin"></i>LinkedIn</button>
                </div>
            </div>
        </div>
    )
}